<template>

  <div>

    <div class="top_style">
      <img alt="Vue logo" src="../../../public/logo.png" style="width: 42px; height: 42px;margin-left: 15px">
      <span class="span_center">Lucky Maker</span>

      <div style="width: 100%;">
        <div style="float: right;margin-right: 15px;">
      
          <span class="tab_type" @click="jumpHome">Home</span>
          <span class="tab_type" @click="jumpAboutUs" style="margin-left: 30px">About Us</span>
          <span class="tab_type" @click="jumpContactUs" style="margin-left: 30px">Contact Us</span>

        </div>
      </div>

    </div>

    <div style="background-color: white; padding: 30px;color: white;">
      <div style="font-size: 60px;color: #444444; font-weight: bold">Privacy policy</div>
      <el-divider></el-divider>

      <div style="font-size: 20px; color: #444444">

        <div>This Privacy Policy describes Lucky Maker’s practices for collecting,
          using, disclosing, maintaining and protecting your personal information on the media platforms we operate,
          including the Lucky Maker app on the iOS or Android platforms (the “App”), our Website, or any other platform
          on
          which you see this Privacy Policy (collectively, the “Services”). We will refer to our users as “you” in this
          Privacy Policy. This Privacy Policy is incorporated into, and part of, the Terms of Use (the “Terms of Use”),
          which governs your use of our Services.
        </div>
        <div style="margin-top: 30px">Please read this notice carefully to understand our policies and
          practices for processing and storing your
          personal information. By accessing and utilizing our Services, you accept and consent to the practices
          described
          in this Privacy Policy. This Privacy Policy may change from time to time (see Changes to our Privacy Policy).
          Your
          continued access and/or use of our Services after any such revisions indicates that you accept and
          consent to them. Please check periodically for updates.
        </div>

        <div style="font-size: 42px;margin-top: 30px;font-weight: bold"> 1. Who We Are</div>
        <div style="margin-top: 30px"> We are Lucky Maker , (“Lucky Maker,” “we,” “our,” “us”).</div>
        <div style="margin-top: 10px">Third Party Links</div>
        <div style="margin-top: 10px"> The platforms on which our Services run may include links to third-party
          services,
          plug-ins and
          applications. Clicking on those links or enabling those connections may allow third parties to collect or
          share
          data about you. We do not control these third-party services and are not responsible for their privacy
          statements.
          When you leave our website, we encourage you to read the Privacy Policy of every other website or app you
          visit.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px"> 2. The Data We Collect About You</div>
        <div style="margin-top: 30px"> We collect several different types of personal information from you
          depending on how you interact with our
          Services. Personal information means any information about an individual from which that person can be
          identified. It does not include information where the identity has been removed or is not able to be
          associated
          with an individual.
        </div>

        <div style="margin-top: 30px"> Most of the information we collect is given to us by you through
          filling out forms, registering for an account, or otherwise providing information when asked. Occasionally, we
          collect information from your device which helps us better provide our services to you. Because we may change
          our
          website and the services we offer from time to time, the means and methods to provide us with personal
          information
          may also change. Depending on how you interact with us and use our services, the personal information we
          collect
          may vary.
        </div>
        <div style="margin-top: 30px"> We may process the following types of personal data about you, which
          we have grouped together as follows:
        </div>

        <el-table
            :data="tableData"
            border
            :show-header="false"
            style="width: 100%;margin-top: 10px;font-size: 16px">
          <el-table-column
              prop="name"
              width="160">
            <template slot-scope="scope">
              <div style="font-weight: bold">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="address">
          </el-table-column>
        </el-table>

        <div style="font-size: 42px; font-weight: bold;margin-top: 30px">3. How We Collect Your Personal Information
        </div>
        <div style="margin-top: 30px">We collect information about you in many different ways. Most of the time we
          collect
          through your direct
          interactions with our Services, but we may also automatically collect personal information from your devices,
          and occasionally from third parties or publicly available sources.
        </div>
        <div style="margin-top: 30px">We collect information about you in the following ways:
        </div>
        <div style="margin-top: 30px">
          <span style="text-decoration: underline">Direct Interactions.</span>
          <span>When you interact with our Services, you may provide us your
        personal information by filling in forms, communicating with us by email, telephone, text/SMS, post, or
        otherwise. This may include personal information you provide when you:</span>
        </div>

        <div style="margin-top: 30px">
          <ul style="margin-left: 20px">
            <li>Subscribe for our Services/Register for an Account;</li>
            <li style="margin-top: 10px">Request that the App be texted to you;</li>
            <li style="margin-top: 10px">Request fulfillment of your order for virtual goods, services, or prize
              payment;
            </li>
            <li style="margin-top: 10px">Request marketing be sent to you;</li>
            <li style="margin-top: 10px">Enter a game, competition, promotion, or survey,</li>
            <li style="margin-top: 10px">Provide us feedback, requests support, or otherwise communicate with Us.</li>
          </ul>
        </div>

        <div style="margin-top: 30px">
          <span style="text-decoration: underline">Automated Collection. </span>
          <span>As you interact with our Services, we may automatically
        collect Technical Information and Usage Information. We collect these categories of Personal Information by
        using cookies, SDK’s, web beacons.
          </span>
        </div>
        <div style="margin-top: 30px">
          <span style="text-decoration: underline">Third Parties or Public Sources. </span>
          <span>We may share and receive personal information about you from various third parties
        as follows:</span>
        </div>

        <div style="margin-top: 30px">
          <li>Technical Information and Usage Information from the following parties:</li>
          <li style="margin-top: 10px;margin-left: 20px">Analytics Providers such as Amplitude (privacy policy) and
            Soomla
            (privacy policy);
          </li>
          <li style="margin-top: 10px;margin-left: 20px">Advertising Networks</li>
          <li style="margin-top: 10px">Contact Information, Transaction Information, and Technical Information from
            providers of technical,payment, communications, and service providers;
          </li>
          <li style="margin-top: 10px">Social Networking Posting Tools and Share features. When you visit a page that
            includes one of these
            sharing tools, the third-party company that operates the tool may be collecting information about your
            browser, device, and other online activity through its own technology not controlled by us. Please read
            their
            privacy policies.
          </li>
          <li style="margin-top: 10px">Identifiers and Technical Information from Social Networks when you grant us
            permission to access data on one or
            more of those services.
          </li>
          <li style="margin-top: 10px"> Publicly visible data, such as your social media and other information submitted
            to social media or other
            publicly available media.
          </li>
        </div>


        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">4. How We Use Your Personal information</div>
        <div style="margin-top: 30px">We will only use your personal information when allowed by law. Generally, we will
          use your personal information: (a) where we need to perform the contract we are about to enter into or have
          entered into with you; (b) where it is necessary for our legitimate interests and your interests and
          fundamental
          rights do not override those interests; and (c) where we need to comply with a legal or regulatory obligation.
        </div>
        <div style="margin-top: 30px;font-weight: bold">Purposes for which we will use your personal information
        </div>
        <div style="margin-top: 30px">Below is a chart of some of the common ways in which we process your personal
          information. We have identified what our legitimate interests are where appropriate. Note that we may process
          your personal information for more than one lawful ground depending on the specific purpose for which we are
          using your data. Please contact us if you need details about the specific legal ground we are relying on to
          process your personal information where more than one ground has been set out in the table below.
        </div>

        <el-table
            :data="titleData"
            border
            :header-cell-style="{fontWeight:'bold',color:'#444444'}"
            style="width: 100%;font-size: 16px">
          <el-table-column
              prop="name"
              label="Purpose/Activity"
              width="320">
          </el-table-column>
          <el-table-column
              prop="title"
              label="Type of data (varies depending on circumstances)"
              width="180">
          </el-table-column>
          <el-table-column
              label="Lawful basis for processing including basis of legitimate interest"
              prop="desc">
          </el-table-column>
        </el-table>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">5. Marketing and Advertising Practices</div>
        <div style="margin-top: 30px">We may reach out to you in different ways, for example, to tell you about new
          features, games, or sweepstakes on the App we think you’d be interested in, or to show you how to use a
          particular feature we think you may enjoy. However, you can also let us know that you’d rather not hear from
          us
          with this type of communication.
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Promotional Offers From Lucky Maker.</span>
          <span>We may use any of the categories of personal information we collect to make
        decisions about what we think you may desire or need as our consumer. We will only make these
        marketing decisions if you agree to receive email marketing from us. In all cases, we allow you to opt-out of
        receiving marketing communications from us.</span>
        </div>

        <div style="margin-top: 30px">
          <span style="font-weight: bold">Interest Based Advertising.</span>
          <span>Lucky Maker uses advertising to help fund our services and we utilize and
        numerous advertising technology platforms to help deliver relevant advertising to you. Lucky Maker and the
        technology service provider may collect, receive, or use your personal data in connection with our use of the
        platform in order to provide interest-based advertising to you. In such cases the advertiser is acting as a
        controller of the data for its own purposes.</span>
        </div>
        <div style="margin-top: 30px">Most of the third-party advertising and data companies we work with, including
          subscribe to the DAA Principles and/or are members of the Network Advertising Initiative (“NAI”). To learn
          more
          about the information collection practices of NAI members and your related opt-out choices, please visit the
          NAI’s website here. For more on EU online choices, please visit the EU online choices website here. You can
          opt
          out of interest-based advertising from Google by visiting the About Google Ads page or opt out of the
          DoubleClick cookie by installing the
          DoubleClick opt out extension. For information about how Google uses data from DoubleClick for Publishers,
          click
          here.
        </div>
        <div style="margin-top: 30px">Please refer to the privacy policies of our advertising partners for more
          information on how to exercise your rights and control your advertising choices. Our advertisers include the
          following:
        </div>


        <li style="margin-top: 30px">MoPub: https://www.mopub.com/legal/privacy/</li>
        <li style="margin-top: 10px">AdColony: https://www.adcolony.com/privacy-policy/</li>
        <li style="margin-top: 10px">Google’s AdMob: https://policies.google.com/privacy</li>
        <li style="margin-top: 10px">AppLovin: https://www.applovin.com/privacy/</li>
        <li style="margin-top: 10px">Facebook: https://www.facebook.com/about/basics/advertising</li>
        <li style="margin-top: 10px">InMobi: https://www.inmobi.com/privacy-policy</li>
        <li style="margin-top: 10px">Unity: https://unity3d.com/legal/cookie-policy#cookies</li>
        <li style="margin-top: 10px">Vungle: http://vungle.com/privacy</li>
        <li style="margin-top: 10px">Fyber: https://www.fyber.com/privacy-policy/</li>
        <li style="margin-top: 10px">Tap Research: https://www.tapresearch.com/legal/privacy-policy-en</li>
        <li style="margin-top: 10px">SOOMLA: https://soomla.com/privacy-policy/</li>


        <div style="margin-top: 30px">
          <span style="font-weight: bold">Mobile App Advertising and Tracking. </span>
          <span>Lucky Maker, our authorized service providers and/or other third-party partners (listed above) involved in the delivery of advertising you receive through our App may collect
information about your mobile device, including your mobile device’s advertising identifiers and the names and IDs of apps you have installed, as well as information about how you use our mobile applications and other
unaffiliated applications. We and our partners use this information to enable interest-based advertising you
receive through our Services and within third-party apps and other marketing channels. Depending on your device and operating system, you may be able to reset your mobile device’s advertising identifier to limit this data collection or prevent the collection of usage data across unaffiliated applications for interest-based
advertising purposes by accessing the privacy settings on your mobile device. Each operating system, iOS for Apple devices, Android for Android devices and Windows for Microsoft devices, provides its own instructions. Visit the privacy settings for your mobile device for more information.
</span>
        </div>
        <div style="margin-top: 30px">In addition, you may see our services advertised in other services. After clicking
          on one of these advertisements and installing our app, you will become a user of our Service. In order to
          verify
          the installs, a device identifier may be shared with the advertiser.
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Social Media Advertising. </span>
          <span>Lucky Maker also runs other kinds of online ad campaigns on social networking
websites and other non-Lucky Maker websites and apps. Some of these ads may be based on information we have about users. If Lucky Maker provides information to a website or app for these advertising purposes, the information may only be used to display the Lucky Maker ad. Please see the privacy policy of the website or app where a Lucky Maker ad is shown for more information about its privacy practices and privacy choices it may offer.</span>
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">6. Disclosures Of Your Personal information
        </div>
        <div style="margin-top: 30px">From time to time, we may need to share your personal information with others,
          including in the following
          circumstances.
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Disclosures to the Public. </span>
          <span>Some of your personal information will be shared with the general public, including your first name and last name initial, city, state, and country. When information is made publicly available it may be accessed by anyone with access to the platform to which the information is posted. It may also be indexed by third-party search engines, and be imported, exported, distributed, aggregated, and redistributed by others
without our knowledge.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Third Party Service Providers. </span>
          <span>We may share your personal information with third party service providers who perform various functions to enable us to provide our services and help us operate our business, such as
website design, sending email communications, fraud detection and prevention, customer care, payment
processing, or performing analytics. Our contracts with these third parties require them to maintain the
confidentiality of the personal information we provide to them, only act on our behalf and under our instructions, and not use personal information for purposes other than the product or service they’re providing to us or on our behalf.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">With our Affiliates and Partners. </span>
          <span>When participating in events or activities that we offer along with our partners and/or affiliates, you may be asked to share personal information with those affiliates and/or partners. For
example, a game, contest, competition, contest, sweepstake or other offering may be co-sponsored by another company or companies. In such a situation, the information we obtain from you in connection with such contest, sweepstake or offering may be shared with our co-sponsor, unless you instruct us not to (which may result in you not being able to participate). In some of those cases, we may act as co-controllers of your personal
information, depending on the circumstances.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">With Unaffiliated Controllers. </span>
          <span>In some cases we may transfer personal information to unaffiliated third-party data controllers. These third parties do not act as agents or service providers and are not performing functions on our behalf. We may transfer your personal information to third-party data controllers for the following
purposes: 1) to provide you with third-party offers; 2) to provide us information about the quality of our services offerings. We will only provide your personal information to third-party data controllers where you have not
opted-out of such disclosures, or in the case of sensitive personal information, where you have opted-in if the disclosure requires consent. We enter into written contracts with any unaffiliated third-party data controllers
requiring them to provide the same level of protection for your personal information that is required of us. We also limit their use of your personal information so that it is consistent with any consent you have provided and with the notices you have received.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Protection of Lucky Maker and Others.</span>
          <span> We may share personal information when we believe it is appropriate to enforce or apply our Terms of Service and other agreements; or protect the rights, property, or safety of Lucky Maker, our products and services, our users, or others. This includes exchanging information with other
companies and organizations for fraud protection and risk reduction. This does not include selling, renting,
sharing, or otherwise disclosing personal information of our customers for commercial purposes in violation of the commitments set forth in this Privacy Policy.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Response to Subpoenas and Other Legal Requests. </span>
          <span>We may share your information with courts, law
enforcement agencies, or other government bodies when we have a good faith belief we’re required or permitted to do so by law, including to meet national security or law enforcement requirements, to protect our company, or to respond to a court order, subpoena, search warrant, or other law enforcement request.
</span>
        </div>
        <div style="margin-top: 30px">
          <span style="font-weight: bold">Sale of Our Business. </span>
          <span>If we sell, merge, or transfer any part of our business, we may be required to share your information. If so, you will be asked if you’d like to stop receiving promotional information following any change of control.
With your Consent. Other than as set out above, we will provide you with notice and the opportunity to choose when your personal information may be shared with other third parties.</span>
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">7. Data Security</div>
        <div style="margin-top: 30px">We have put in place appropriate security measures to prevent your personal
          information from being
          accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access
          to your personal information to those employees, agents, contractors and other third parties who have a
          business
          need to know. They will only process your personal information on our instructions and they are subject to a
          duty of confidentiality. We have put in place procedures to deal with any suspected personal information
          breach
          and will notify you and any applicable regulator of a breach where we are legally required to do so.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">8. Data Retention</div>
        <div style="margin-top: 30px">We will only retain your personal information for as long as necessary to fulfil
          the
          purposes we collected it for,
          including for the purposes of satisfying any legal, accounting, or reporting requirements. To determine the
          appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the
          personal information, the potential risk of harm from unauthorized use or disclosure of your personal
          information, the purposes for which we process your personal information and whether we can achieve those
          purposes through other means, and the applicable legal requirements. In some circumstances you can ask us to
          delete your data (see “EU Data Subjects Legal Rights”). In some circumstances we may anonymize your personal
          information (so that it can no longer be associated with you) for research or statistical purposes in which
          case
          we may use this information indefinitely without further notice to you.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">9. International Data Transfers</div>
        <div style="margin-top: 30px">Lucky Maker in the United States. Information we collect from you will be
          processed
          in the United States. Where we transfer EU data subject’s personal information to third parties outside of the
          European Economic Area (EEA), we rely on appropriate suitable safeguards or specific derogations recognized
          under data protections law, including the GDPR. The European Commission has adopted standard data protection
          clauses, which provide safeguards for Personal information transferred outside of the EEA. We may use Standard
          Contractual Clauses when transferring Personal information from a country in the EEA to a country outside the
          EEA.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">10. EU Data Subjects Privacy Rights</div>
        <div style="margin-top: 30px">EU data subjects have certain rights with respect to your personal information
          that
          we collect and process. We respond to all requests we receive from individuals in the EEA wishing to exercise
          their data protection rights in accordance with applicable data protection laws.
        </div>
        <div style="margin-left: 10px">
          <li style="margin-top: 30px">
            <span style="font-weight: bold">Access, Correction or Deletion. </span>
            <span>You may request access to, correction of, or deletion of your personal
information. You can often go directly into the Service under Account Settings to take these actions. Please note that even if you request for your personal information to be deleted, certain aspects may be retained for us to: meet our legal or regulatory compliance (e.g. maintaining records of transactions you have made with us); exercise, establish or defend legal claims; and to protect against fraudulent or abusive activity on our Service. Data retained for these purposes will be handled as described in Section 9 “Data Retention”, above.
</span>
          </li>
          <li style="margin-top: 30px">
            <span style="font-weight: bold">Objection. </span>
            <span>You may object to processing of your personal information where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal information for direct marketing
purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
</span>
          </li>
          <li style="margin-top: 30px">
            <span style="font-weight: bold">Restriction. </span>
            <span>You have the right to ask us to suspend the processing of your personal information in the
following scenarios: (a) if you want us to establish the data’s accuracy; (b) where our use of the data is
unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer
require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
</span>
          </li>
          <li style="margin-top: 30px">
            <span style="font-weight: bold">Portability. </span>
            <span>You have the right to request the transfer of your personal information to you or to a third party. We will provide to you, or a third party you have chosen, your personal information in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
</span>
          </li>
          <li style="margin-top: 30px">
            <span style="font-weight: bold">Withdraw Consent. </span>
            <span>If we have collected and processed your personal information with your consent, you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.
</span>
          </li>
          <li style="margin-top: 30px">
            <span style="font-weight: bold">File a complaint. </span>
            <span>You have the right to file a complaint with a supervisory authority about our collection and processing of your personal information.</span>
          </li>
        </div>

        <div style="margin-top: 30px">To file a request or take action on one of your rights, please contact us at the
          contact details provided. You will not have to pay a fee to access your personal information (or to exercise
          any
          of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive
          or excessive. Alternatively, we may refuse to comply with your request in these circumstances.
          We may need to request specific information from you to help us confirm your identity and ensure your right to
          access your personal information (or to exercise any of your other rights). This is a security measure to
          ensure
          that personal information is not disclosed to any person who has no right to receive it. We may also contact
          you
          to ask you for further information in relation to your request to speed up our response. We try to respond to
          all legitimate requests within one month. Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this case, we will notify you and keep you
          updated.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">11. Children’s Privacy</div>
        <div style="margin-top: 30px">We are committed to complying with the laws protecting the privacy of children,
          including the United States’
          Children’s Online Privacy Protection Act (COPPA). Our Services are not directed to children under the age of
          16.
          We do not knowingly collect personal information from children under the age of 16. If we receive personal
          information that we discover was provided by a child under the age of 16, we will promptly destroy such
          information. If you would like to learn more about COPPA, visit the Federal Trade Commission home page at
          http://www.ftc.gov.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">12. Your California Privacy Rights (Under
          California
          Civil Code Section 1798.83)
        </div>
        <div style="margin-top: 30px">California residents have the right to request and obtain from us, once a year and
          free of charge, a list of the third parties to whom we have disclosed certain types of personal information
          (if
          any) for their direct marketing purposes in the prior calendar year. At our option, we may respond to such
          requests by providing instructions about how our users can exercise their options to prevent our disclosure of
          personal information to third parties for their direct marketing purposes. You can read these instructions
          above
          in the “Marketing and Advertising Practices” section of our Privacy Policy. Or, if you are a California
          resident
          and prefer that we send you a
          separate response, please email your request to support@luckymakerapp.com. At this time, our Website does not
          respond to browser “Do Not Track” signals.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">13. Changes to our Privacy Policy</div>
        <div style="margin-top: 30px">By using this website, you agree to the terms and conditions contained in this
          Privacy Policy and Conditions of Use and/or any other agreement that we might have with you. If you do not
          agree
          to any of these terms and
          conditions, you should not use this website. You agree that any dispute over privacy or the terms contained in
          this Privacy Policy will be governed by the laws of the State of California. You also agree to arbitrate such
          disputes in California, and to abide by any limitation on damages contained in any agreement we may have with
          you.
        </div>
        <div style="margin-top: 30px">This Policy is expected to change from time to
          time. We reserve the
          right to amend this Privacy Policy at any time and provide notice to you by posting of the amended Privacy
          Policy on the website. We may also email you to give you notice of material changes to this Privacy Policy.
          The
          provisions contained herein supersede all
          previous notices or statements regarding our privacy practices and the terms and conditions that govern the
          use
          of this Services.
        </div>

        <div style="font-size: 42px;font-weight: bold;margin-top: 30px">14. How to Contact Us</div>
        <div style="margin-top: 30px">If you have any questions or wish to register a complaint in relation to this
          Privacy Policy or the manner in which your personal information is used by us, please contact us by any of the
          following means:
        </div>
        <div style="margin-top: 30px">Lucky Maker .</div>
        <div style="margin-top: 10px">Attn: Privacy Department</div>
        <div style="margin-top:10px">support@luckymaker.com</div>
        <div style="margin-top:10px">COMPANY: FOX INFORMATION PTE. LTD.</div>
        <div style="margin-top:10px">LOCATION:160 ROBINSON ROAD #20-03 SINGAPORE (068914)</div>
        <div style="margin-top:10px">EMAIL:support@luckymaker.com</div>

      </div>
    </div>

    <div style="padding-bottom: 30px;padding-top:30px;background: #2F1A5E;text-align: center">
      <span class="sp_type" @click="jumpPrivacyPolicy">privacy policy</span>
      <span class="sp_type" @click="jumpUsePolicy" style="margin-left: 60px">Terms of use</span>
    </div>

  </div>

</template>

<script>
export default {
  name: "PrivacyAgreement",

  data() {
    return {
      tableData: [
        {
          name: 'Identifiers',
          address: 'This may include your name (first and last), a username (or similar identifier), data of birth, gender, and profile photo if you upload it.'
        },
        {
          name: 'Contact \n' + 'Information',
          address: 'This may include your email address, mailing address, telephone number.'
        },
        {
          name: 'Transaction \n' +
              'Information',
          address: 'This may include information you provide for the purposes of sending payments to you, such as the email address associated with your \n' +
              'PayPal account. It also includes the records of payments made to you, the amounts paid to you, and other information associated with payment \n' +
              'transactions.'
        },
        {
          name: 'Usage \n' +
              'Information',
          address: 'This may include information about the game features you use, your game preferences, the types of games you play, how long you use our \n' +
              'Services, and how you use our Services.'
        },
        {
          name: 'Account \n' +
              'Information',
          address: 'This may include your username and password, your preferences, feedback and survey responses.'
        },
        {
          name: 'Marketing and \n' +
              'Communications \n' +
              'Information',
          address: 'This may include your communications preferences in receiving marketing from us and/or third parties.'
        },
        {
          name: 'Geolocation Data',
          address: 'We collect your geolocation data whenever you choose to access the rewards or wallet features and play our raffle and lottery scratcher games. \n' +
              'We use this data to determine which currency to pay you in, which rewards to offer, and our legal obligations in that jurisdiction.'
        },
      ],
      titleData: [
        {
          name: 'Lawful basis for processing including basis of legitimate interest',
          title: 'Contact Information \n Account Information',
          desc: 'Performance of a contract with you.',
        },
        {
          name: 'Notifying you about changes to our terms or Privacy Policy.',
          title: 'Contact Information',
          desc: 'Necessary to comply with a legal obligation',
        },
        {
          name: 'To enable you to participate in a game, contest, competition, \n' +
              'survey, or similar event.',
          title: 'Identifiers\n Contact Information\n Technical Information\n Usage Information',
          desc: 'Performance of a contract with you.\n' +
              '\n' +
              'Legitimate Interests (to learn how our users use our Services, to \n' +
              'improve our Services, to grow our business).',
        },
        {
          name: 'To administer and protect our business and this website \n' +
              '(including troubleshooting, data analysis, testing, system \n' +
              'maintenance, support, reporting and hosting of data).',
          title: 'Identifiers\n' +
              'Contact Information\n' +
              'Technical Information\n' +
              'Usage Information',
          desc: 'Necessary for our legitimate interests (for running our business, \n' +
              'provision of administration and IT services, network security, to prevent \n' +
              'fraud and in the context of a business reorganization or group \n' +
              'restructuring exercise)',
        },
        {
          name: 'To provide you with information and marketing \n' +
              'communications about our products and services.',
          title: 'Identifiers\nContact Information\nMarketing and \n' +
              'Communications \n' +
              'Information',
          desc: 'Consent\n' +
              '\n' +
              'Legitimate Interests (in marketing goods or services in which you may \n' +
              'have a personal interest based on our ongoing business relationship).',
        },
        {
          name: 'To provide you with third party offers that may \n' +
              'be relevant to you.',
          title: 'Identifiers\nContact Information\nMarketing and \n' +
              'Communications \n' +
              'Information',
          desc: 'Consent',
        },
        {
          name: 'To respond to customer service requests including \n' +
              'order status and chat communications.',
          title: 'Identifiers\nContact Information\nTechnical Information',
          desc: 'Legitimate Interest (for running our business, provision of administration \n' +
              'and IT services, network security, to prevent fraud and in the context of \n' +
              'a business reorganization or group restructuring exercise)\n' +
              '\n' +
              'Consent',
        },
      ],
    }
  },
  methods: {
    /**
     * 打开首页
     */
    jumpHome() {
      this.$router.push({
        path: '/',
      });
    },
    /**
     * 打开关于我们界面
     */
    jumpAboutUs() {
      this.$router.push({
        path: '/aboutUs',
      });
    },
    /**
     * 打开联系我们界面
     */
    jumpContactUs() {
      this.$router.push({
        path: '/contactUs',
      });
    },
    /**
     * 打开隐私协议
     */
    jumpPrivacyPolicy() {
      this.$router.push({
        path: '/PrivacyAgreement',
      });
    },
    /**
     * 打开用户协议
     */
    jumpUsePolicy() {
      this.$router.push({
        path: '/UserAgreement',
      });
    },
  },
}
</script>

<style scoped>

.top_style {
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  padding: 10px;
  background: linear-gradient(78deg, #6131C4, #623BF0);
}

.sp_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}

.span_center {
  width: 100%;
  font-weight: bold;
  font-size: 26px;
  color: white;
  margin-left: 10px;
}

.tab_type {
  font-size: 16px;
  color: white;
  font-family: Arial;
}


</style>